
.auth {
  @apply  flex-col sm:flex-row w-full      ;
}
.auth .swiper {
  flex: 1;
}

.auth .swiper-wrapper {
  @apply h-[82vh] 
}

.auth .swiper-slide {
  @apply w-full flex justify-center items-center 
}

.circle {
  @apply w-5 h-5 rounded-[50px] border-2 border-white flex justify-center items-center transition-[background,color] duration-500 ease-in-out mx-[15px]
}

.dot { 
  @apply w-3 h-3 rounded-[50px] transition-[background,color] duration-75
}

.line {
  @apply w-[1.5px] sm:h-11 h-[50px] bg-white rounded-sm sm:my-2 my-1  mx-[-7px] relative rotate-90 sm:rotate-0
}

.subline {
  @apply absolute left-0 right-0 top-0 bg-orange h-0 transition-[height] duration-300
}

.sublineActive {
  @apply h-full
}
