.transitionWidth {
  transition: width .3s linear;
}

.transitionAppearance {
  animation: transitional 1s forwards;
}

@keyframes transitional {
  0% {
    opacity: 0;
    transform: translateX(-17%);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0%);
  }
}

.enterWidth {
  animation: enterWidth .3s normal;
  transition: width .3s step-start;
}

@keyframes enterWidth {
  0% {
    width: 20px;
  }
  100% {
    width: 190px;
  }
}

.leaveWidth {
  animation: leaveWidth .5s backwards;
  transition: width .5s step-end;
}

@keyframes leaveWidth {
  0% {
    width: 190px;
  }
  100% {
    width: 20px;
  }
}

.navContent::-webkit-scrollbar {
  width: 0px;
}